.New-Password-section {
  width: 40%;
  margin: 90px auto;
}

.New-password {
  text-align: center;
  margin: 50px 0px;
}
input#exampleInputEnter\ OTP1 {
  width: 100%;
}

h4.New-password-heading {
  font-size: 25px;
  color: #292929;
}

.Save-btn-section {
  text-align: center;
}

button.Save-btn {
  font-size: 19px;
  font-weight: 500;
  width: 75%;
  background-color: #fd6a02;
  padding: 9px 0px;
  border-radius: 6px;
  color: white;
  border: none;
  margin-top: 23px;
}
.Save-btn-section {
  margin-top: 3%;
}

.password_div {
  position: relative;
}

.password_eye .fa {
  position: absolute;
  font-size: 20px;
  right: 15px;
  top: 20px;
  cursor: pointer;
}

/* media queries */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .New-Password-section {
    width: 100%;
  }
  i.fa.fa-eye-slash {
    top: 12px;
  }
  i.fa.fa-eye {
    top: 12px;
  }
  // input#exampleInputEnter\ OTP1 {
  //     width: 100%;
  // }
  h4.New-password-heading {
    font-size: 18px;
  }
  .form-control {
    height: 45px;
  }
  label {
    font-size: 14px;
  }

  button.Save-btn {
    font-size: 17px;
    padding: 7px 0px;
  }

  .Set-new-password {
    width: 75%;
    margin: 0px auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .New-Password-section {
    width: 90%;
  }

  .Save-btn-section {
    font-size: 18px;
    padding: 7px 0px;
  }

  button.Save-btn {
    padding: 7px 0px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .New-Password-section{
    width: 70%;
    margin-bottom: 36%;
  }
}
@media (min-width: 910px) and (max-width: 913px){
  .New-Password-section{
    margin-bottom: 62%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 1023px) and (max-width: 1025px) {
  .New-Password-section {
    margin-bottom: 15%;
  }
}
@media (min-width: 992px) and (max-width: 1249px){

}
/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .New-Password-section {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px) {
  .New-Password-section {
    width: 40%;
  }
}
