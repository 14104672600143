* {
  margin: 0;
  padding: 0;
}
.profileImage-main {
  width: 186px;
  margin: auto;
  height: 186px;
  border-radius: 50%;
  background: #ffbd8e;
  font-size: 25px;
  color: #f47321;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 65px;
}
.OpenTodayHeading {
  font-family: "Lato";
}
a .reviews {
  color: #292929;
}

h2.name-heading {
  font-size: 24px;
  color: #f47321;
  font-family: "Gugi", cursive;
  align-self: center;
}

.main-pageHeading {
  margin-left: 30px;
  align-self: center;
  align-items: center;
}

.main-pageHeader {
  display: flex;
}

.rating-Section {
  display: flex;
  align-items: center;
}

.dv-star-rating {
  pointer-events: none;
}

h2.reviews {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 0;
  margin: 0 10px;
}

.location-part {
  display: flex;
}

h4.location-heading {
  font-weight: 500;
  font-size: 18px;
  padding: 0 15px;
  color: #292929;
  font-family: "Lato";
}

.time-part {
  display: flex;
}

h4.time-heading {
  font-weight: 500;
  font-size: 18px;
  padding: 0 15px;
  color: #292929;
  padding-top: 6px;
  display: flex;
}

.phone-part {
  display: flex;
}

h4.number {
  font-weight: 500;
  display: flex;
  font-size: 18px;
  padding: 0 15px;
  color: #292929;
  padding-top: 4px;

  align-self: center;
  justify-items: center;
}

.detail-section {
  margin-top: 5%;
}
i.fa.fa-map-marker {
  color: #f6843e;
  font-size: 24px;
  padding-bottom: 7px;
  padding-left: 2px;
  padding-right: 3px;
}

i.fa.fa-clock-o {
  color: #f6843d;
  font-size: 22px;
  padding-top: 5px;
}
.imgSection {
  height: 100px;
  width: 100px;
}

img.profile-img {
  width: 186px;
  height: 186px;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  object-position: 14% 55%;
}
.RedirectFooter {
  color: black !important;
  font-size: 18px;
}

.RedirectFooter span {
  font-size: 18px;
  font-family: "Lato";
}
.fa-mobile {
  color: #f6843d;
  font-size: 28px;
  padding-left: 4px;
}
img.clockIcon {
  width: 18px;
}
span.phone {
  margin-left: 3px;
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  img.profile-img {
    width: 75px;
    height: 75px;
    border-radius: 75px;
  }

  .main-pageHeading h2.name-heading {
    font-size: 22px;
    margin-bottom: 0;
  }

  .rating-Section h2.reviews {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .location-part h4.location-heading {
    font-size: 12px;
    padding: 5px 12px;
  }

  .time-part h4.time-heading {
    font-size: 12px;
    padding: 5px 12px;
    margin: 3px 0px;
  }

  .phone-part h4.number {
    font-size: 12px;
    padding: 5px 12px;
    align-items: center;
  }

  .detail-section {
    margin-top: 6%;
  }
  .main-pageHeading {
    margin-left: 10px;
  }
  h2.reviews {
    font-size: 16px;
  }
  .RedirectFooter span {
    font-size: 12px;
  }
  .phone-part {
    display: none;
  }
  .RedirectFooter span {
    font-size: 16px;
  }
  .profileImage-main {
    width: 75px;
    height: 75px;
    font-size: 25px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  img.profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  h2.reviews {
    font-size: 16px;
  }
  .phone-part {
    display: none;
  }
  .profileImage-main {
    width: 150px;
    height: 150px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .detail-section {
    margin-top: 2%;
  }

  h2.reviews {
    font-size: 16px;
  }

  img.profile-img {
    width: 131px;
    height: 131px;
    border-radius: 75px;
  }

  .main-pageHeading h2.name-heading {
    font-size: 20px;
    margin: 0;
  }

  .rating-Section h2.reviews {
    font-size: 14px;
  }

  .location-part h4.location-heading {
    font-size: 12px;
    padding: 5px 12px;
  }

  .time-part h4.time-heading {
    font-size: 12px;
    padding: 5px 12px;
  }

  .phone-part h4.number {
    font-size: 12px;
    padding: 5px 12px;
  }
  .phone-part {
    display: flex;
  }
  .RedirectFooter span {
    font-size: 14px;
  }
  .main-pageHeading {
    margin-left: 14px;
  }
  i.fa.fa-map-marker {
    font-size: 18px;
  }
  i.fa.fa-clock-o {
    font-size: 18px;
  }
  .fa-mobile {
    font-size: 22px;
  }
  .profileImage-main {
    width: 131px;
    height: 131px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
}
