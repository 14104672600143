button.Submit-btn {
  font-size: 19px;
  font-weight: 500;
  width: 75%;
  background-color: #fd6a02;
  padding: 9px 0px;
  border-radius: 6px;
  color: white;
  border: none;
  margin-top: 23px;
}

.forgotten-password-section {
  //   width: 50%;
  margin: 25px auto;
}
.phone-number-feild {
  width: 512px;
  margin: auto;
}

.mobileNumber {
  text-align: center;
  margin: 25px 0px;
}

.sign-up-btn-section {
  text-align: center;
}

h6.dontHaveAccount-heading {
  margin-top: 30px;
  font-size: 16px;
  color: #292929;
}

h4.enter-mobile-heading {
  font-weight: 400;
  font-size: 25px;
}
a.submit {
  color: #fd6a02 !important;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
ul.list-unstyled a:hover {
  color: #fff;
}

/* media queries */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .forgotten-password-section {
    width: 100%;
  }

  h4.enter-mobile-heading {
    font-size: 16px;
  }
  h6.dontHaveAccount-heading {
    display: none;
  }
  button.Submit-btn-section {
    font-size: 17px;
    padding: 7px 0px;
  }

  .phone-number-feild {
    width: 75%;
    margin: 0 auto;
    margin-top: 73px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .forgotten-password-section {
    width: 100%;
  }

  button.Submit-btn-section {
    font-size: 18px;
    padding: 7px 0px;
  }
  h6.dontHaveAccount-heading {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .forgotten-password-section {
    width: auto;
  }
  .phone-number-feild {
    margin-bottom: 293px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .phone-number-feild {
    margin-bottom: 160px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
  h6.dontHaveAccount-heading {
    margin-bottom: 178px;
  }
}
