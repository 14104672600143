.newCard input#gridRadios1 {
  display: none;
}

h4.Payment-heading {
  font-family: "Gugi", cursive;
  color: #f47321;
  font-size: 20px;
  font-weight: 400;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f47321 !important;
  background-color: #f47321 !important;
  margin-top: 5px;
}

.custom-control-label::after {
  margin-top: 5px;
}

.form-check {
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  padding: 10px 30px;
}

span.ecpiry-date {
  display: table;
  font-size: 12px;
}

h5.visa-heading {
  display: inline-block;
}
.rightTick {
  position: relative;
  left: -15px;
  top: 5px;
  margin-left: 6px;
  cursor: pointer;
}
input#gridRadios2 {
  display: none;
}
.hairSection {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.CuttingStyle {
  width: 464px;
  margin: auto;
  background-color: #f4f4f4;
  padding: 28px 94px 27px 43px;
  border-radius: 12px;
}
.ExpirationRow {
  display: flex;
  width: 100%;
}
.ExpirationRow .firstSection {
  width: 35%;
}
.ExpirationRow .SecondSection {
  width: 35%;
  margin-left: 10px;
}
.ExpirationRow .ThirdSection {
  width: 100%;
  margin-left: 18px;
}
input#exampleInputName1 {
  border-radius: 10px;
  height: 50px;
  width: 100%;
  border-color: #858585;
}
.mainbox {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;
}
.CardDetailsSection {
  width: 100%;
  background-color: #f4f4f4;
  padding: 42px 100px;
  border-radius: 13px;
  margin-bottom: 24.5rem;
}
.CardDetailsSection input#exampleInputName1{
height: 40px;
background-color: #f4f4f4;
}
input#CardInputName1{
  height: 40px;
}
.CardDetailsSection #exampleInputName1:hover{
border-color: #858585 !important;
} 
.CardDetailsSection #exampleInputName1:focus{
  border-color: #858585 !important;
}
input#CardInputName1:focus {
  border-color: #858585 !important;
}
input#CardInputName1:hover {
  border-color: #858585 !important;
}
.modal-body input#exampleInputName1{
  height: 40px;
}
h4.AddCard-heading {
  color: #f47321;
}
.firstInputField {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ExpirationRow label {
  margin: 10px 0;
}
span.slash {
  margin-left: 10px;
}
.CardDetailsSection input#exampleInputCard1 {
  border-radius: 10px;
  height: 49px;
  border-color: #858585;
}
button.btn.btn-outline-primary.addCard-button {
  color: #ffffff;
  border-color: #f47321;
  padding: 10px 80px;
  border-radius: 8px;
  font-size: 18px;
  background-color: #f47321;
  box-shadow: none;
  font-family: "Lato";
}
.NewCardButton.text-center {
  margin-top: 27px;
}
.VisaField .form-check {
  position: relative;
  display: flex;
  padding-left: 1.25rem;
  justify-content: space-between;
  align-items: center;
}
.VisaField {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  .delet-icon {
    width: 20px;
    cursor: pointer;
  }
}
.VisaField .form-check {
  /* position: relative; */
  display: flex;
  /* padding-left: 1.25rem; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 74px;
  padding: 0 20px 0 14px;
}
span.visa-icon {
  background-color: #d9d9d9;
  padding: 8px 15px;
  border-radius: 3px;
  font-weight: 900;
  margin-right: 10px;
  position: relative;
  color: BLACK;
  top: 10px;
}
.vesaBox {
  width: 100%;
  padding: 0 85px;
  margin-top: 41px;
}

h4.SelectPaymentHeading {
  font-size: 20px;
  color: #f47321;
  margin-bottom: 0;
}
.bold {
  font-weight: 700;
}
span.ecpiry-date {
  font-size: 11px;
  color: #858585;
  margin-top: 4px;
}
h5.visa-heading {
  font-size: 16px;
  font-family: "Lato";
}
h5.totalPrice {
  font-size: 18px;
  font-weight: 700;
}
h5.hairHeading {
  font-size: 18px;
}
button.addCard-button {
  margin-top: 25px;
}
.date-confirm-model,
.date-confirm-time {
  font-size: 25px;
  margin: 0;
}
.date-confirm-time {
  margin-bottom: 50px;
}
.PriceSec {
  width: 15%;
}
.CardDetailsSection label {
  font-size: 18px;
}
.mobileAddCard {
  display: none;
}
h5#exampleModalLabel {
  color: #f47321;
}
img.plusSecond-icon {
  margin-right: 12px;
}
.CuttingStyle h5 {
  font-family: "Lato";
}
.appointment-model p {
  font-family: "lato";
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 767px){
  .CardDetailsSection { 
    display: none;
   }
   .mobileAddCard {
    display: block;
    margin-top: 40px;
    margin-left: -20px;
  }
} 
@media (max-width: 768px) {
  .CuttingStyle {
    width: 100%;
    padding: 28px 45px 28px 15px;
  }
  .appointment-model p.apn-confirmed-model {
    font-size: 20px;
  }
  .date-confirm-time {
    font-size: 20px;
  }
  p.date-confirm-model {
    font-size: 20px;
  }
  button.btn.btn-outline-primary.addCard-button {
    font-size: 16px;
    margin-bottom: 20px;
  }
  h4.SelectPaymentHeading {
    font-size: 18px;
    margin: 13px 0px;
  }
  .form-check {
    width: 80%;
  }

  .vesaBox {
    width: 100%;
    padding: 0;
    margin-top: 28px;
  }
  .VisaField {
    padding: 0;
  }
  
  fieldset.form-group.row.VisaFieldSection {
    width: 100%;
    margin: 0;
  }
  .addOnMobile {
    display: block;
  }
  .advanceSection {
    width: 100%;
  }
  .addOnMobile {
    padding: 0 10px;
  }
  svg.bi.bi-plus-circle.addMobilesvg {
    color: #f47321;
    height: 25px;
    width: 25px;
    margin: 0 10px;
  }
  button.btn.btn-outline-primary.OnMobile {
    font-size: 16px;
    padding: 10px 67px;
  }
  .mainbox {
    padding: 15px;
  }
  button.addCard-button {
    padding: 10px 40px !important;
  }
  .VisaField .form-check {
    padding-right: 12px;
    padding-left: 17px;
  }
  h5.hairHeading {
    font-size: 16px;
  }

  .newCard {
    margin-bottom: 50%;
  }
  h2.AddCard {
    color: #292929;
    font-size: 16px;
    padding: 4px 10px;
  }
 
  .ExpirationRow {
    display: flex;
  }
  .SecondSection {
    margin-right: 15px;
  }
  .firstInputField {
    margin-right: 15px;
    display: flex;
  }
  span.slash {
    padding: 3px 0px 0px 12px;
    font-size: 20px;
    color: black;
  }
  .modal-header {
    border-bottom: 0 !important;
    padding-bottom: 0;
  }
  button.btn.AddNewCard-BTN {
    border: none;
  }
  .modal.show .modal-dialog {
    transform: none;
    top: 0%;
    position: relative;
    margin: auto;
  }
  .blockPopup .block-button {
    padding: 15px 20px !important;
  }
  .blockPopup p {
    margin: 15px;
  }
  .appointment-model{
    margin: 0 !important;
  }
  .paymentModal .modal-content {
    margin: 20px;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  input#exampleInputName1 {
    width: 100%;
  }
  .CardDetailsSection {
    padding: 42px 21px;
  }
  .advanceSection {
    width: 100%;
  }
  .CuttingStyle {
    width: 100%;
    padding: 28px 45px 28px 15px;
  }

  .vesaBox {
    padding: 0 2px;
  }
  .ThirdSection input#exampleInputCard1 {
    margin: 0 10px;
  }
  span.slash {
    margin: 0 4px;
  }

  .addOnMobile {
    display: none;
  }
  h5.hairHeading {
    font-size: 16px;
  }
  // h4.AddCard-heading {
  //   font-size: 16px;
  // }
.blockPopup .block-button {
    padding: 15px 20px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .CardDetailsSection {
    width: 353px;
    padding: 42px 21px;
  }
  .CardDetailsSection input#exampleInputName1 {
    width: 100%;
  }
  .ExpirationRow .firstSection {
    width: 74px;
    margin: 0 10px;
  }
  .firstInputField input#exampleInputCard1 {
    width: 74px;
  }
  .ExpirationRow .SecondSection {
    width: 74px;
    margin: 0 10px;
  }
  .ExpirationRow .ThirdSection {
    width: 163px;
  }
  .ThirdSection input#exampleInputCard1 {
    width: 136px;
  }
  .vesaBox {
    padding: 0;

    width: 352px;
  }
  .CuttingStyle {
    padding: 28px 32px 28px 28px;
    width: 351px;
  }
  span.slash {
    margin-left: 7px;
  }
  .mainbox {
    float: right;
  }

  .addOnMobile {
    display: none;
  }
.block-button {
    padding: 15px 40px !important;
  }
}

.appointment-model {
  text-align: center;
  margin: 30px;

  .apn-confirmed-model {
    margin: 10px;
    margin-bottom: 0;
    color: #f47321;
    font-size: 25px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
  .addOnMobile {
    display: none;
  }
.block-button {
    padding: 15px 40px !important;
  }
}

@media (min-width: 900px) and (max-width: 992px) {
  .CardDetailsSection {
    margin-bottom: 36rem;
  }
}

.blockPopup {
  .block-button {
    margin: 9px;
    padding: 15px 40px;
  }
  p {
    text-align: center;
    font-family: "Lato";
    margin: 25px;
  }
  .btnDiv {
    justify-content: center;
  }
}
button.btn.btn-outline-primary.block-button {
  color: #ffffff;
  border-color: #f47321;
  padding: 10px 80px;
  border-radius: 8px;
  font-size: 18px;
  background-color: #f47321;
  box-shadow: none;
  font-family: "Lato";
}
