* {
  margin: 0;
  padding: 0;
}

a {
  color: #ffffff;
  font-size: 14px;
}

/* footer CSS */

footer.page-footer.font-small.blue {
  background-color: #f47321;
  margin-top: 5%;
  // height: 230px;
}
ul.list-unstyled a {
  font-weight: 100;
}
.footer-copyright {
  font-size: 14px;
  text-align: center;
}
.InstagramIcon {
  margin: 0px 13px 0 16px;
  // width: 15px;
  object-fit: cover;
  color: #fff;
}
i.fa.fa-facebook {
  color: #fff;
}
img.foterLcotion-icon {
  margin: 0 17px;
  margin-left: 14px;
}
.email-icon {
  // width: 20px;
  margin: 0 13px;
}
.footerSocail-section {
  margin-top: 10px;
}
.InstagramIcon {
  margin: 16px 26px;
}

.footer-copyright {
  font-size: 14px;
  color: white;
}

hr.FooterHR {
  border-top: 1px solid #cdcdcd !important;
  opacity: 1;
}

.copyright-heading {
  margin-top: -5px;
}

h6.copyright {
  color: #fff;
  font-size: 14px;
}
.list-unstyled {
  text-align: start;
}
ul.list-unstyled li {
  padding: 5px 0px;
}
hr.ratingHR {
  display: none;
}

img.footer-logo {
  cursor: pointer;
  width: 136px;
}
.Main-footerBottom {
  font-family: "Lato";
}
.footer-copyright.text-center {
  font-family: "Lato";
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .Main-footerBottom {
    display: none;
  }
  .pt-4 {
    padding-top: 0rem !important;
  }

  .footer-copyright.text-center {
    padding: 10px 0 !important;
  }
  //   footer.page-footer.font-small.blue {
  //     height: 45px;
  //   }
  .footerComment-section {
    margin: 0;
  }
  hr.ratingHR {
    display: block;
    margin: 20px 15px 0px;
  }
  footer.page-footer.font-small.blue {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }

  .footer-copyright {
    padding-top: 13px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 767px) {
  footer.page-footer.font-small.blue {
    padding-top: 10px !important;
  }
  .Main-footerBottom {
    display: none;
  }
  footer.page-footer.font-small.blue {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  img.footer-logo {
    width: 98px;
  }
  .email-icon {
    margin: 0;
    margin-right: 6px;
  }
  a.footerEmail-heading {
    font-size: 12px;
  }
  img.foterLcotion-icon {
    margin: 0px 9px 0 1px;
  }
  a.footerLocation-heading {
    font-size: 12px;
  }
  ul.list-unstyled {
    width: max-content;
  }
  footer.page-footer.font-small.blue {
    // margin-top: 16.2%;
  }
  .Main-footerBottom a {
    font-size: 13px;
  }
}
@media (min-width: 1020px) and (max-width: 1025px){
  footer.page-footer.font-small.blue{
    margin-top: 35%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  // footer.page-footer.font-small.blue {
  //   height: 230px;
  // }
  .email-icon {
    width: 16px;
    margin: 0px 3px;
  }
  img.foterLcotion-icon {
    margin: 0px 5px 0 5px;
  }
  .searchBarbar-Section {
    min-height: calc(100vh - 285px);
}
}
@media (min-width: 1249px) {
  // footer.page-footer.font-small.blue {
  //   margin-top: 12.9%;
  // }
  .searchBarbar-Section {
    min-height: calc(100vh - 170px);
}
}

/* Navbar css */
button.btn.btn-success.my-2.my-sm-0.signin {
  background-color: #f47321;
  border: none;

  padding: 6px 30px;
  font-size: 18px;
  border-radius: 6px;
}

.Main-navbar {
  border-bottom: 1px solid gray;
}
