.searchBarbar {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

svg.BarbarLoction-icon {
  width: 28px;
  padding-right: 8px;
}

.barbarRatingSec {
  margin-top: 8px;
}

h4.BarbarName {
  margin-top: 10px;
}

h2.searchResultHeading {
  color: #858585;
  font-family: "Gugi";
}

.BarberSearch-Sec input {
  font-size: 14px !important;
}

h4.BarbarName {
  color: #f47321;
  font-size: 25px;
  font-family: "Gugi";
}

.barbarLocation {
  font-size: 15px;
  display: -webkit-box;
  color: #292929;
}

h4.foundHeading {
  color: #858585;
  font-weight: 400;
  font-size: 25px;
  font-family: "Lato";
}

.SearchResult {
  margin-top: 20px;
  margin-bottom: 30px;
}

.barbarSearch {
  border: 1px solid #d9d9d9;
  margin-top: 40px;
  cursor: pointer;
  margin-bottom: 70px;
}

img.BarbarImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.DetailsSearch {
  padding: 10px;
}

.viewMoreSec {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  align-items: center;
  margin-top: 40px;
  padding: 10px;
}

button.searchBtn {
  color: #fff;
  border: none;
  background-color: #f47321;
  padding: 17px 60px;
  border-radius: 4px;
  margin: 0 12px;
  position: relative;
  font-family: "Lato";
  top: 5px;
}

.searchBarbar input#exampleInputEmail1 {
  padding: 10px 20px;
  border-radius: 4px !important;
  width: 339px;
  font-family: "Lato";
}

p.OrHeading {
  padding: 0 15px;
  margin-bottom: -10px;
}

h5.NearHeading {
  display: none;
}

.barbarRatingSec {
  display: flex;
  align-items: center;
}

span.ReviesHeading {
  color: #858585;
  padding: 0 10px;
  text-decoration: underline;
}

.viewIcon i {
  font-size: 22px;
  color: #858585;
}

input.search-input {
  width: 230px;
  padding: 10px 30px 10px 10px;
  border: 1px solid#858585;
  border-radius: 4px !important;
  outline: none;
  font-family: "Lato";
}

button.searchBtn {
  width: 100%;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.barberSearch-icon {
  position: absolute;
  top: 17px;
  right: 10px;    
  cursor: pointer;
}

h6.selectYourService {
  color: #292929;
  font-family: lato;
}

input.search-input::placeholder {
  font-size: 18px;
}
img.barberLocationIcon {
  width: 20px;
}
.barberSearch-icon:hover + .onhover {
  display: block !important;
}
.box {
  background-color: #f4f4f4;
  color: #929292;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 4px 12px 15px 12px;
  border-radius: 3px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 25% 80%, 20% 100%, 15% 80%, 0 80%);
}
.onhover {
  position: absolute;
  top: -16px;
  right: -92px;
  z-index: 5;
  display: none;
}
// media Queries

@media (max-width: 767px) {
.box {
  display: none;
}
  .searchBarbar {
    display: block;
    text-align: center;
  }
  input.search-input::placeholder {
    font-size: 12px;
  }
  .onhover{
    right: 0;
    top: -22px;
  }
  .searchBarbar input#exampleInputEmail1 {
    padding: 10px 30px 10px 10px;
    height: 50px;
    width: 100%;
  }
  input.search-input {
    position: relative;
    height: 50px;
    width: 100%;
  }
  .searchBarbar {
    text-align: center;
  }
  p.OrHeading {
    margin: 10px 0;
  }
  button.searchBtn {
    margin: 15px 0;
    height: 50px;
    width: auto;
    padding: 14px 50px;
  }
  button.searchBtn {
    padding: 12px 60px;
  }
  h2.searchResultHeading {
    font-size: 18px;
  }
  h4.foundHeading {
    font-size: 13px;
  }
  .SearchResult {
    margin-bottom: 0;
  }
  .barbarSearch {
    border: none;
    margin-top: 22px;
    display: flex;
    margin-bottom: 0;
  }
  h4.BarbarName {
    font-size: 14px;
  }
  .barbarLocation {
    font-size: 11px;
  }
  span.BarbarLoction-icon svg {
    width: 11px;
  }
  span.BarbarLoction-icon {
    padding-right: 6px;
  }
  span.BarbarRatingIcon img {
    width: 16px;
  }
  span.ReviesHeading {
    font-size: 11px;
  }
  img.BarbarImage {
    width: 84px;
    height: 79px;
    object-fit: cover;
    border-radius: 8px;
  }
  .viewMore {
    display: none;
  }
  .viewMoreSec {
    border-top: none;
    margin-top: 0;
    padding: 0;
  }
  .DetailsSearch span {
    font-size: 10px;
  }
  .DetailsSearch {
    padding: 0 0 0 10px;
    width: 100%;
  }
  h4.BarbarName {
    margin: 5px 0;
    margin-bottom: 0;
  }
  .searchBarbar-Section {
    margin-bottom: 45%;
  }
  svg.BarbarLoction-icon {
    width: 20px;
  }
  .ellipsis {
    width: 200px;
  }
  .barbarRatingSec {
    margin-top: -9px;
  }
  .searchBar1 {
    position: relative;
    margin-top: 30px;
  }
  .searchBar2 {
    position: relative;
  }
  .SearchIcon {
    position: absolute;
    right: 15px;
    top: 12px;
  }
  .barberSearch-icon {
    top: 12px;
  }
  .PaymentRequired {
    font-size: 12px;
  }
  h6.PaymentRequired {
    min-height: 15px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media (min-width: 768px) and (max-width: 992px) {
  .searchBarbar input#exampleInputEmail1 {
    padding: 15px 7px 15px 10px;
    width: fit-content;
  }
  button.searchBtn {
    padding: 17px 35px;
    position: relative;
  }
  .searchBarbar {
    margin: 20px 0;
  }
  .SearchResult {
    margin: 0px 0;
  }
  h2.searchResultHeading {
    font-size: 25px;
  }
  h4.foundHeading {
    font-size: 17px;
  }
  h5.NearHeading {
    display: block;
    margin-top: 30px;
    font-weight: 300;
  }
  h4.BarbarName {
    font-size: 20px;
  }
  span.ReviesHeading {
    font-size: 15px;
  }
  .viewMoreSec {
    margin-top: 30px;
  }
  input.search-input {
    width: 214px;
  }
  .SearchResult {
    min-height: 10rem;
  }
  footer.page-footer.font-small.blue {
    margin-top: 8%;
  }
  .PaymentRequired {
    font-size: 14px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media (min-width: 992px) and (max-width: 1200px) {
  h4.BarbarName {
    font-size: 20px;
  }
  .searchBarbar input#exampleInputEmail1 {
    padding: 15px 10px 15px 10px;
  }
  .SearchResult {
    min-height: 6rem;
  }
  .PaymentRequired {
    font-size: 14px;
  }
}

.form-control:isactive {
  .exampleInputEmail1 {
    color: "red";
  }
}

.Search-icon1 {
  color: #ff6a00;
}

.Search-icon2 {
  color: #ff6a00;
}

@media (min-width: 1200px) {
  .SearchResult {
    min-height: 8rem;
  }
}

h6.PaymentRequired {
  min-height: 21px;
}

.currentLocationButton {
  cursor: pointer;
}
