.thinLine {
  height: 0.5px;
  background-color: #929191;
  opacity: 0.4;
  margin-bottom: 38px;
}

#profileImage {
  width: 52px;
  margin: auto;
  height: 52px;
  border-radius: 50%;
  background: #ffbd8e;
  font-size: 25px;
  color: #f47321;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
}

#tabProfileImage {
  width: 112px;
  margin: auto;
  height: 112px;
  border-radius: 50%;
  background: #ffbd8e;
  font-size: 40px;
  color: #f47321;
  text-align: center;
  line-height: 112px;
}

/* Navbar css */
button.btn.my-2.my-sm-0.signin {
  background-color: #f47321;
  border: none;
  font-size: 18px;
  border-radius: 6px;
  padding: 7px 45px;
  width: max-content;
  font-family: lato;
  font-style: normal;
  outline: none;
  color: white;
}

button.btn.my-2.my-sm-0.Login {
  border: none;
  background-color: #292929;
  padding: 6px 30px;
  border-radius: 6px;
  padding: 7px 48px;
  width: max-content;
  font-size: 18px;
  font-family: lato;
  font-style: normal;
  outline: none;
  color: white;
}

.Main-navbar {
  border-bottom: 1px solid gray;
}

// Dropdown menu css
.profile-part {
  text-align: center;
}

h2.Profile-F-heading {
  font-weight: 600;
  font-size: 18px;
  margin-top: 13px;
  color: #292929;
}

h3.profile-number-heading {
  font-size: 18px;
  font-weight: 400;
  color: #292929;
}

button.manage-prefrence {
  border: 1px solid #858585;
  padding: 0px 27px;
  font-size: 18px;
  height: 42px;
  line-height: 19px;
  border-radius: 30px;
  color: #292929;
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: white;
  font-family: "lato";
}

button.btn.profile-logout-button {
  border: 1px solid #858585;
  padding: 5px 30px;
  font-size: 18px;
  font-family: "lato";
}

form .modal-footer {
  display: block !important;
}

h4.Download-app {
  font-size: 18px;
  color: #f47321;
  font-weight: 400;
}

.available-on {
  margin-top: 20px;
}

h6.more-feature-heading {
  font-size: 14px;
  font-weight: 400;
  color: #292929;
  width: 75%;
  margin: auto;
  padding-top: 10px;
}

.dropdownProfileMenu {
  position: absolute;
  width: 27%;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  z-index: 11;
  right: 3rem;
  background-color: #ffffff;
  border-radius: 27px;
  top: 6rem;
  padding: 30px 0px;
}

img.profile-image2 {
  height: 52px;
  width: 52px;
  border-radius: 50%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
hr.profileHR {
  margin: 1.5rem 0;
}
/*Responsive Mobile Menu */
.overlay-menu {
  background: rgb(255, 255, 255);
  color: rgb(13, 26, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  padding-right: 15px;
  transform: translateX(-100%);
  width: 85vw;
  height: 136vh;
  -webkit-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  z-index: 4;
}

.overlay-menu ul,
.overlay-menu li {
  display: block;
  position: relative;
  left: 10px;
}

.overlay-menu li a {
  display: block;
  font-size: 1.8em;
  letter-spacing: 4px;
  padding: 0px 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.nav-downloading-section {
  margin: 35px 0px;
}

.nav-downloading-section {
  margin-bottom: 22px;
  margin-top: 20%;
}

.humburger-nav-Details h6 {
  color: #858585;
  font-weight: 400;
  font-size: 16px;
}

a.humburger-anchor {
  display: block;
  color: #292929 !important;
  font-size: 16px;
  text-decoration: none;
}
p.nav-para {
  font-size: 14px;
}

h5.nav-download-heading {
  color: #f47321;
}

.logo {
  text-align: start;
}

.humburger-nav-Details {
  margin-bottom: 35px;
}

//  Navbar

#navbar {
  background: rgba(255, 255, 255, 0);
  width: 100%;
}
img.navbar-logo {
  margin: 20px 0px;
  cursor: pointer;
  width: 148px;
}
.nav-wrapper {
  margin: auto;
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-icon {
  display: flex;
  border-radius: 50%;
}

.header-icon .profile-image {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.tab-profile-image {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  object-fit: cover;
}

#navbar ul {
  display: flex;
  list-style: none;
  margin: 20px 0;
  text-align: right;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
}

#navbar li {
  display: inline-block;
  margin: 0 5px;
}

#navbar li a {
  color: rgb(13, 26, 38);
  display: block;
  font-size: 0.7em;
  height: 50px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.apple-Store-Image,
.Google-Store-Image {
  cursor: pointer;
}

.available-on a {
  padding: 12px 0;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 767px) {
  .menuIcon {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    height: 23px;
    width: 27px;
    z-index: 12;
  }
  .nav-wrapper {
    width: 100%;
    margin: 0;
  }

  #navbar ul {
    display: none;
  }

  /* Icon Bars */
  .icon-bars {
    background: rgb(255, 255, 255);
    position: absolute;
    left: 1px;
    top: 45%;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  img.nav-apple-image {
    padding: 5px;
  }

  .icon-bars::before {
    background: rgb(255, 255, 255);
    content: "";
    position: absolute;
    left: 0;
    top: -8px;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    margin-top: 0px;
    background: rgb(255, 255, 255);
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  /* Bars Shadows */
  .icon-bars.overlay {
    background: rgb(97, 114, 129);
    background: rgb(183, 199, 211);
    width: 20px;
    animation: middleBar 3s infinite 0.5s;
    -webkit-animation: middleBar 3s infinite 0.5s;
  }

  @keyframes middleBar {
    0% {
      width: 0px;
    }

    50% {
      width: 20px;
    }

    100% {
      width: 0px;
    }
  }

  @-webkit-keyframes middleBar {
    0% {
      width: 0px;
    }

    50% {
      width: 20px;
    }

    100% {
      width: 0px;
    }
  }

  @keyframes topBar {
    0% {
      width: 0px;
    }

    50% {
      width: 10px;
    }

    100% {
      width: 0px;
    }
  }

  @-webkit-keyframes topBar {
    0% {
      width: 0px;
    }

    50% {
      width: 10px;
    }

    100% {
      width: 0px;
    }
  }

  @keyframes bottomBar {
    0% {
      width: 0px;
    }

    50% {
      width: 15px;
    }

    100% {
      width: 0px;
    }
  }

  @-webkit-keyframes bottomBar {
    0% {
      width: 0px;
    }

    50% {
      width: 15px;
    }

    100% {
      width: 0px;
    }
  }

  /* Toggle Menu Icon */
  .menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0;
  }

  .menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }

  button.btn.btn-success.my-2.my-sm-0.signin {
    width: 93%;
    height: 50px;
    font-size: 16px;
    margin: 15px 0px !important;
  }

  button.btn.btn.btn-dark.my-2.my-sm-0.Login {
    height: 50px;
    width: 93%;
    font-size: 16px;
    margin-bottom: 25px !important;
  }

  img.navbar-logo {
    width: 65%;
  }

  .background {
    background-color: #ec7728;
    height: 3.1rem;
  }

  img.navbar-logo {
    display: none;
  }

  img.navbar-logo-white {
    width: 86px;
    margin: 15px 0;
  }

  #navbar {
    line-height: 1;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 767px) {
  #navbar ul {
    display: none;
  }
  .nav-wrapper {
    margin: 0;
  }

  #navbar li a {
    margin: 0;
  }
  button.btn.btn-success.my-2.my-sm-0.signin {
    width: 93%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .nav-wrapper {
    width: 93%;
  }

  a.navbar-brand-white {
    display: none;
  }
  .dropdownProfileMenu {
    right: 2rem;
    top: 6rem;
    width: 45%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  a.navbar-brand-white {
    display: none;
  }
  .dropdownProfileMenu {
    width: 35%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px) {
  a.navbar-brand-white {
    display: none;
  }
}
.header-icon:hover, focus {
  box-shadow: 0px 0px 0px 4px #f47321;
  // border-radius: 50%;
}