.Verify-OTP-section {
  width: 50%;
  margin: 100px auto;
}

.OTP-Number {
  text-align: center;
  margin: 50px 0px;
}

h4.enter-OTP-heading {
  font-size: 25px;
  color: #292929;
}
button.Confirm-btn {
  font-size: 19px;
  font-weight: 500;
  width: 75%;
  background-color: #fd6a02;
  padding: 9px 0px;
  border-radius: 6px;
  color: white;
  border: none;
  margin-top: 23px;
}

.Confirm-btn-section {
  text-align: center;
}

h6.didnt.Get-OTP {
  margin-top: 20px;
  font-size: 18px;
  color: #858585;
}

a.resend {
  color: #fd6a02;
}

.resend {
  cursor: pointer;
}
input#exampleInputEnter\ OTP1 {
  width: 512px;
  margin: auto;
}
.OTP-number-feild {
  margin: 0px 68px;
}
label.resend {
  font-size: 18px;
  color: #f47321 !important;
  border-bottom: 1px solid #f47321;
}

/* media queries */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .Verify-OTP-section {
    width: 100%;
  }
  label.resend {
    font-size: 16px;
  }
  h6.didnt.Get-OTP {
    font-size: 14px;
  }
  h4.enter-OTP-heading {
    font-size: 18px;
  }

  button.Confirm-btn {
    font-size: 17px;
    padding: 7px 0px;
  }

  .OTP-number-feild {
    width: 75%;
    margin: 0px auto;
  }
  .Verify-OTP-section {
    margin: 0px auto;
    margin-top: 25px;
    margin-bottom: 215px;
  }
  .OTP-Number {
    margin-bottom: 75px;
    margin-top: 25px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .Confirm-btn-section {
    font-size: 18px;
    padding: 7px 0px;
  }

  .Verify-OTP-section {
    width: 100%;
  }

  button.Confirm-btn {
    padding: 7px 0px;
  }
  .OTP-number-feild {
    margin: 0px 10px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .Verify-OTP-section {
    width: 91%;
    min-height: calc(100vh - 607px);
  }
  .OTP-number-feild {
    margin: 0px 61px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .Verify-OTP-section {
    width: 69%;
  }
}
@media (min-width: 1023px) and (max-width: 1025px){
.Verify-OTP-section {
  margin-bottom: 18%;
}}
@media (min-width: 1200px) and (max-width: 1249px) {
  .Verify-OTP-section {
    width: 58%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px) and (max-width: 1300px) {
  .Verify-OTP-section {
    width: 58%;
  }
}
