h2#service-heading {
  font-family: "Gugi", cursive;
  color: #f47321;
  font-size: 28px;
}

h5.gents-heading {
  display: inline-block;
  font-family: "Graphik";
  font-size: 16px;
  margin-top: 16px;
  font-family: "Lato";
}

h6.gents-cut-time {
  font-size: 12px;
  position: relative;
  top: -5px;
  font-family: "Lato";
}

button.select-date:focus {
  background-color: #f47321;
  color: white;
}

span.price {
  float: right;
  font-size: 14px;
  margin-top: 12px;
  font-family: "Lato";
}

div#service-components {
  margin-top: 48px;
  height: 300px;
  overflow-y: auto;
}

h5.monday {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato";
}

.day-and-time {
  justify-content: space-between;
  flex: 1 1;
  display: flex;
  margin: 15px 0;
  align-items: center;
}

h6.time-close {
  display: inline-block;
  // float: right;
  font-size: 13px;
  margin-right: 87px;
}

h6.time {
  display: inline-block;
  float: left;
  font-size: 13px;
  font-weight: 400;
  font-family: "Lato";
}

.hair-cut-BtnSection {
  text-align: center;
}

h2.opening-heading {
  color: #f47321;
  font-size: 20px;
  margin-top: 22px;
  margin-bottom: 25px;
  font-family: "Gugi";
}

.hours-deatils {
  padding: 0px 30px;
  border-radius: 15px;
}

.opening-hours-section {
  width: 100%;
  background-color: #f4f4f4;
  padding: 0px 80px;
  border-radius: 15px;
  padding-top: 25px;
}

.gents-cut-details {
  display: flex;
  margin-top: 5px;
  align-items: center;
  padding: 5px 12px;
  border-radius: 5px;
}

button.Hair-cut-heading {
  padding: 6px 35px;
  margin: 10px;
  border-radius: 6px;
}

button.select-date {
  padding: 13px 0px;
  border-radius: 6px;
  margin-top: 40px;
  width: 100%;
  font-size: 18px;
  color: #858585;
  background-color: #e2e2e2;
  border: none;
  margin-bottom: 40px;
  font-family: "Lato";
}
.service-subdiv {
  margin-left: 15px;
  flex: 1;
}
svg.bi.bi-check-circle {
  height: 28px;
  width: 28px;
  color: #858585;
  margin: 0 5px;
}
.doubleTime {
  display: grid;
}
h6.closed {
  color: #858585;
  font-weight: 300;
}
h5.closed {
  color: #858585;
  font-weight: 300;
}
.costSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-right: 30px;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 65px;
}
h4.costHeading {
  font-size: 20px;
  color: #f47321;
  margin-top: 10px;
}
h6.totalMinutes {
  font-size: 12px;
  font-family: "Lato";
}
h5.totalCost {
  font-size: 20px;
  color: #f47321;
  font-family: "Lato";
}
.MoreTime {
  height: 300px;
  // margin-bottom: 40px;
  overflow-y: auto;
  padding-right: 20px;
}
.add-another-services span {
  cursor: pointer;
  font-size: 15px;
}

// scrollbar CSS
.MoreTime::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.MoreTime::-webkit-scrollbar-track {
  background: rgb(194, 194, 194); /* color of the tracking area */
  border-radius: 10px;
}

.MoreTime::-webkit-scrollbar-thumb {
  background-color: #f47321; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(255, 255, 255); /* creates padding around scroll thumb */
}

div#service-components::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

div#service-components::-webkit-scrollbar-track {
  background: rgb(194, 194, 194); /* color of the tracking area */
  border-radius: 10px;
}

div#service-components::-webkit-scrollbar-thumb {
  background-color: #f47321; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(255, 255, 255); /* creates padding around scroll thumb */
}
h4.SelectDateHeading {
  font-family: "Gugi";
  font-size: 18px;
}
h4.costHeading {
  font-size: 17px;
  width: fit-content;
}
h4.totalTime {
  font-size: 15px;
  width: fit-content;
}
button.btn-close {
  box-shadow: none !important;
}
/* media Queries */

@media (max-width: 600px) {
  .opening-hours-section {
    width: 100%;
    padding: 0;
    background-color: #fff;
    height: fit-content;
  }
  .select-btnSection {
    display: none;
  }

  button.Hair-cut-heading {
    padding: 4px 25px;
    margin: 8px;
  }

  .hours-deatils {
    padding: 0;
    background-color: #fff;
  }
  .day-and-time {
    margin: 15px 0;
  }
  h2#service-heading {
    font-size: 20px;
  }

  h5.gents-heading {
    font-size: 13px;
  }

  h6.gents-cut-time {
    font-size: 10px;
  }

  span.price {
    font-size: 14px;
    margin-top: 12px;
  }
  .costSection {
    padding-right: 10px;
    text-align: center;
    width: 95%;
    margin: auto;
    margin-top: 38px;
  }
  div#service-components {
    margin-top: 0;
    max-height: 300px;
    height: auto;
  }
  .gents-cut-details {
    padding: 0 15px;
    margin-right: 10px;
  }
  h5.monday {
    font-size: 13px;
  }
  h6.time {
    font-size: 13px;
  }
  h5.totalCost {
    font-size: 17px;
  }

  button.select-date {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 16px;
  }
  .costSection {
    display: none;
  }
  .costSection.Mobile {
    display: flex;
  }
  h4.costHeading {
    color: black;
  }
  h6.totalMinutes {
    color: #f4792b;
  }
  h4.SelectDateHeading {
    font-size: 16px;
  }
  .react-calendar__navigation__label__labelText--from {
    font-size: 15px;
  }
  .opening-hours-section {
    background-color: #fff;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .opening-hours-section {
    width: 100%;
    padding: 0 0;
    background-color: #fff;
    padding-top: 15px;
    height: auto;
  }

  .hours-deatils {
    padding: 40px 0px;
  }

  button.Hair-cut-heading {
    padding: 6px 30px;
  }
  .costSection {
    display: none;
  }
  .costSection.Mobile {
    display: flex;
    width: 95%;
  }
  h4.costHeading {
    color: black;
  }
  h6.totalMinutes {
    color: #f4792b;
  }
  h4.SelectDateHeading {
    font-size: 16px;
  }
  .react-calendar__navigation__label__labelText--from {
    font-size: 15px;
  }
  .gents-cut-details {
    margin-right: 40px;
    padding: 0 15px;
  }
  div#service-components {
    margin-top: 0;
    max-height: 300px;
    height: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .hours-deatils {
    padding: 15px 0;
  }
  h2#service-heading {
    font-size: 19px;
  }
  .opening-hours-section {
    width: 100%;
    padding: 20px 30px;
    height: 500px;
  }
  button.select-date {
    font-size: 16px;
  }
  button.Hair-cut-heading {
    padding: 6px 20px;
    margin: 8px;
  }
  .costSection.Mobile {
    display: none;
  }
  .gents-cut-details {
    margin-right: 20px;
    padding: 0 10px;
    padding-right: 20px;
  }
  .costSection {
    padding-right: 0;
  }
  .timer-slide .timer-btn {
    font-size: 11px;
  }
  h4.costHeading {
    font-size: 20px;
  }
  button.select-date {
    margin-top: 35px;
  }
  h6.totalMinutes {
    font-size: 14px;
  }
  div#service-components {
    margin-top: 25px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  button.Hair-cut-heading {
    padding: 7px 22px;
    margin: 7px;
  }
  .opening-hours-section {
    padding: 50px 40px;
  }
  .costSection.Mobile {
    display: none;
  }
  .gents-cut-details {
    padding-right: 40px;
    margin-right: 30px;
  }
  .costSection {
    padding-right: 15px;
  }
  button.select-date {
    margin-top: 55px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
  .costSection.Mobile {
    display: none;
  }
  .gents-cut-details {
    margin-right: 65px;
    padding-right: 35px;
  }
  .select-btnSection {
    margin-top: 40px;
  }
}

i.fa.fa-check {
  border: 1px solid #858585;
  border-radius: 50%;
  padding: 6px;
  color: #fff;
}
