.newCard .Checkout {
  text-align: center;
}
input.InputElement {
  border: 1px solid #858585 !important;
  border-radius: 4px !important;
}

.stripMainDiv {
  position: relative;
  z-index: 0;
  background-color: transparent;
  left: 0px;
}
.stripMainSpan1 {
  position: absolute;
  width: 100% !important;
  top: -33px;
  height: 40px;
  left: 0;
  border: 1px solid #858585;
    outline: none;
    font-family: "Lato";
   border-radius: 10px ;
}

.StripeElement{
  position: relative;
  z-index: 10;
  font-size: 14px !important;
  left: 4px;
}

button.btn.btn-outline-primary.extra {
  background-color: #fff;
  color: #f47321;
}

button.btn.btn-outline-primary.extra:focus {
  color: #fff;
  background-color: #f47321;
}

// media queries
@media (max-width: 767px){
 
}
@media (min-width: 767px) and (max-width:992px){


}
/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px)and (max-width: 1400px) {

}
@media (min-width:1400px){
  .stripMainSpan1 {
    height: 40px;
  }
}