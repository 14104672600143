.MapBackground-section {
  background-color: #f4f4f4;
  padding: 55px 0px;
  margin-top: 50px;
}
iframe {
  height: 20rem;
}

.social-media-details {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 13px;
}
.social-media-details h6 {
  font-size: 12px;
  // color: black;
}

/* vertical slider css */
.left {
  float: left;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#slider {
  box-sizing: border-box;

  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

#slider * {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 300;
}

#slide {
  width: 100%;
  height: 400px;
}

#slide ul {
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

#slide ul li {
  position: relative;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

#slide ul li .img {
  width: 75%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: 0px 0;
  display: block;
  background-size: cover;
  padding-left: 0%;
  pointer-events: none;
  border-top: 3px solid #f47321;
  border-left: 3px solid #f47321;
  border-bottom: 3px solid #f47321;
  margin: 0.5px;
}
img.thubnail-shop-image {
  border: 2px solid #f47321;
  object-fit: cover;
}

#slide ul li a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 33.35%;
  height: 400px;
}

#thumbnail {
  width: 25%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

#thumbnail .thumbnail-list {
  box-shadow: inset 0px 0px 50px 5px rgba(0, 0, 0, 0.5);
}

#thumbnail .thumbnail-list li {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  cursor: pointer;
  padding: 42px 15px 0 36px;
  line-height: 1.3;
  height: 133.5px;
  position: relative;
  z-index: 1;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  // border-left: 1px solid rgba(0, 0, 0, 0.5);
}

#thumbnail .thumbnail-list li:last-child {
  border-bottom: none;
}
img.slider_images {
  border-bottom: 5px solid #f47321;
}

#thumbnail .thumbnail-list li.marker {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  z-index: 0;
}

#thumbnail .thumbnail-list li.active span {
  font-size: 24px;
  line-height: 1;
}

#thumbnail .thumbnail-list li p {
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  font-size: 14px;
}

#thumbnail .thumbnail-list li span {
  font-size: 16px;
  letter-spacing: -0.1px;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

.blur-img {
  width: 300%;
  height: 400px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background-position: 100% 0;
  background-size: cover;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

// .blur-content {
//   height: 400px;
//   overflow: hidden;
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: 33.3%;
// }

img.thumbnail-hair-cutting-image {
  width: 100%;
  // aspect-ratio: 1;
  height: auto;
}

img.thubnail-shop-image {
  width: 100%;
  height: 100%;
}

li.active {
  padding: 0;
}

li {
  padding: 0;
}

.instagram-details h6 {
  font-family: "Lato";
}

#thumbnail .thumbnail-list li {
  padding: 0;
}

img.thumbnail-hair.cutting-style-image {
  width: 100%;
}

li.image-border {
  border-width: 10;
  border-color: black;
}

.instagram-details {
  cursor: pointer;
  margin: 0 22px;
}
.instagram-details:hover {
  color: #f47321;
  transition: 0.2s all;
}
.call-details:hover {
  color: #f47321 !important;
  transition: 0.2s all;
}
svg.bi {
  width: 17px;
  height: 17px;
  margin-bottom: 8px;
  margin-top: 14px;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0) !important;
}
.tablet-view {
  display: block;
}
.tab-none {
  display: none;
}

/* media queries */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .thumbnail-list {
    display: none;
  }

  .slide {
    width: 100%;
    height: 400px;
  }

  .carousel-root {
    width: 90vw;
  }
  .instagram-details {
    margin: 0 18px;
  }
  .instagram-details h6 {
    font-size: 12px;
  }
  img.call-icon {
    width: 17px;
    height: 17px;
    margin-bottom: 8px;
    margin-top: 14px;
  }
  .call-details {
    margin: 0 18px;
    display: block;
  }
  #slide {
    height: 300px;
  }
  ul.slider.animated {
    height: 300px;
  }
  li.slide.selected {
    height: 300px;
  }
  li.slide.selected div {
    height: inherit;
  }
  li.slide.previous div {
    height: inherit;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  img.thumbnail-hair.cutting-style-image {
    width: 100%;
  }

  img.thumbnail-hair-cutting-image {
    width: 100%;
    height: auto;
  }

  img.thubnail-shop-image {
    width: 100%;
  }

  .thumbnail-list {
    display: none;
  }

  .slide {
    width: 100%;
    height: 400px;
  }

  .carousel-root {
    width: 450px;
  }
  .call-details {
    margin: 0 18px;
    display: block;
  }
  img.call-icon {
    width: 17px;
    height: 17px;
    margin-bottom: 8px;
    margin-top: 14px;
  }
  #slide {
    height: 300px;
  }
  ul.slider.animated {
    height: 300px;
  }
  li.slide.selected {
    height: 300px;
  }
  li.slide.previous div {
    height: inherit;
  }
  li.slide.selected div {
    height: inherit;
  }
  img.thumbnail-hair-cutting-image {
    height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .thumbnail-list {
    display: none;
  }  
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  li {
    width: 100%;
  }

  .tablet-view {
    display: none;
  }

  .tablet-view-multiple {
    display: block;
  }
  .slide {
    width: 100%;
    height: 400px;
  }

  .carousel-root {
    width: 40vw;
  }
  .instagram-details {
    margin: 0 14px;
  }
  .call-details {
    display: none;
  }
  li.slide.selected div {
    height: inherit;
  }

  img.thumbnail-hair-cutting-image {
    height: 400px;
    object-fit: contain;
  }
  // .tablet-view {
  //   display: none;
  // }
  // .tab-none {
  //   display: none;
  // }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  img.thumbnail-hair-cutting-image {
    height: 400px;
    object-fit: contain;
  }
  .call-details {
    display: none;
  }
  .tablet-view {
    display: none;
  }
  .tablet-view-multiple {
    display: none;
  }
  .tab-none {
    display: block;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px) {
  .call-details {
    display: none;
  }
  img.thumbnail-hair-cutting-image {
    height: 400px;
    object-fit: contain;
  }
  .tablet-view {
    display: none;
  }
  .tablet-view-multiple {
    display: none;
  }
  .tab-none {
    display: block;
  }
}

img.slider_images {
  width: 100%;
  object-fit: cover;
  height: inherit;
  border-bottom: 6px solid #f47321;
}
