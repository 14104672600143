@import url("https://fonts.googleapis.com/css2?family=Gugi");
@import url("//db.onlinewebfonts.com/c/105007d99d9df64c50cc24d696d79555?family=Graphik+Web+Regular");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato";
}

select,
input {
  height: 60px;
  border-radius: 10px !important;
  font-size: 18px !important;
  border-color: #858585 !important;
}

select:hover,
input:hover {
  border-color: #fd6a02 !important;
}

.form-control:focus {
  border-color: #fd6a02 !important;
  box-shadow: none !important;
}

label {
  font-size: 20px;
  color: #292929;
  opacity: 0.8;
  margin: 2% 0;
}

@media (max-width: 600px) {
  select,
  input {
    font-size: 12px !important;
  }
}
@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url(./assets/fonts/105007d99d9df64c50cc24d696d79555.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
