@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700&family=Gugi&family=Lato:wght@400;700;900&family=Slabo+13px&display=swap");
.sign-up-btn {
  text-align: center;
}
.main {
  flex: auto;
}

.signUp-section {
  width: 40%;
  margin: 0px auto;
  font-family: "Lato";
}

h2.continue-heading {
  font-family: "Gugi";
  margin: 20px 0;
  color: #fd6a02;
  font-size: 25px;
  text-align: center;
}

.top-signin-login-tab {
  display: inline-flex;
  justify-content: space-between;
  // background-color: #000;
  width: 100%;
}
li.nav-item {
  width: 50%;
}

div#nav-tab {
  text-align: center;
  display: block;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border-bottom: 1px solid #ea6202;
  color: #000 !important;
}

a.nav-link {
  cursor: pointer;
}

button.LogIn-btn- {
  font-size: 19px;
  font-weight: 500;
  width: 50%;
  background-color: #fd6a02;
  padding: 7px 0px;
  border-radius: 6px;
  color: white;
  border: none;
  margin-top: 23px;
}

button.signning-btn {
  font-size: 20px;
  font-weight: 500;
  width: 360px;
  height: 60px;
  background-color: #fd6a02;
  padding: 7px 0px;
  border-radius: 8px;
  color: white;
  border: none;
  margin-top: 23px;
  font-family: lato;
  font-style: normal;
}

h6.already-have-heading {
  margin-top: 18px;
  font-size: 14px;
  flex-direction: "row";
}

h6.terms-condition {
  font-size: 13px;
  flex-direction: "row";
  text-decoration: underline;
  margin-bottom: 170px;
  cursor: pointer;
}

a.Log_in {
  color: #fd6a02;
}

a.Log_in:hover {
  color: #fd6a02;
}

div#nav-tab {
  text-align: center;
  display: block;
  margin-top: 45px;
  margin-bottom: 30px;
}

.nav-tabs {
  border-bottom: none !important;
  width: 100%;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border-bottom: 5px solid black;
  color: #000 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fd6a02;
  background-color: #fff !important;
  border-bottom-color: #fd6a02 !important;
  border-bottom: 4px solid #fd6a02 !important;
}

a#nav-active-tab {
  font-size: 20px;
  color: #fd6a02 !important;
  font-family: "Lato", sans-serif;
  font-style: normal;
}

a#nav-inactive-tab {
  font-size: 20px;
  font-weight: 400;
  color: #858585 !important;
  margin: 0px 40px;
  font-family: "Lato", sans-serif;
  font-style: normal;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 0px solid transparent !important;
  color: #000 !important;
}

.profile-image-section {
  text-align: center;
  margin: 24px;
}
h6.forgotPassword {
  text-align: end;
  font-size: 15px;
  color: #858585;
  margin-top: 3%;
  cursor: pointer;
  margin-bottom: 30px;
}
.already-have-heading label {
  font-size: 14px;
}

.password_div {
  position: relative;
}

.password_eye .fa {
  position: absolute;
  font-size: 20px;
  right: 15px;
  top: 20px;
  cursor: pointer;
}
svg.bi.bi-chevron-down {
  position: absolute;
  right: 20px;
  top: 60px;
  color: #858585;
}
h6.already-have-headings {
  font-size: 14px;
  margin-top: 15px;
}
label.signUpHeading {
  font-size: 14px;
}
.form-group {
  margin-top: 10px !important;
  position: relative;
}
select#exampleFormControlSelect1 {
  color: #555555;
}
.UploadImage {
  margin-top: 50px;
  margin-bottom: 30px;
}
.AddImage {
  position: absolute;
  right: 0;
  bottom: 5px;
}
input#file {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 34;
  opacity: 0;
}
.userImage {
  width: fit-content;
  margin: auto;
  position: relative;
}

img.upload-image {
  width: 158px;
  height: 158px;
  border-radius: 50%;
}

/* media queries */
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  h2.continue-heading {
    font-size: 22px;
  }

  .signUp-section {
    width: 90%;
  }

  a#nav-active-tab {
    margin: 0px;
    font-size: 16px;
  }
  a#nav-inactive-tab {
    margin: 0px;
    font-size: 16px;
  }
  button.signning-btn {
    width: 100%;
    height: 40px;
    font-size: 16px;
  }

  img.profile-image {
    width: 35%;
  }
  li.nav-item {
    width: 50%;
  }
  h6.forgotPassword {
    font-size: 13px;
  }
  svg.bi.bi-chevron-down {
    right: 13px;
    top: 37px;
  }
  h6.already-have-headings {
    margin-top: 12px;
  }
  h2.continue-heading {
    display: none;
  }
  h6.already-have-heading {
    font-size: 12px;
  }
  h6.terms-condition {
    font-size: 12px;
  }
  img.upload-image {
    width: 112px;
    height: 112px;
  }
  img.add-image {
    width: 32px;
  }
  .UploadImage {
    margin-top: 27px;
    margin-bottom: 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .signUp-section {
    width: 90%;
  }
  .UploadImage {
    margin-top: 27px;
    margin-bottom: 24px;
  }
  img.upload-image {
    width: 112px;
    height: 112px;
  }
  img.add-image {
    width: 32px;
  }
  .signUp-section {
    width: 70%;
  }
  a#nav-inactive-tab {
    width: 100%;
    margin: 0;
  }
  #nav-active-tab {
    margin: 0;
    width: 100%;
  }
  li.nav-item {
    width: 50%;
  }
  h6.forgotPassword {
    font-size: 18px;
  }
  label {
    font-size: 18px;
  }
  button.signning-btn {
    font-size: 20px;
  }
  h6.already-have-heading {
    font-size: 13px;
  }
  svg.bi.bi-chevron-down {
    right: 15px;
    top: 50px;
  }
  h6.already-have-heading {
    font-size: 12px;
  }
  h6.terms-condition {
    font-size: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .signUp-section {
    width: 70%;
    min-height: calc(100vh - 1px);
  }
  h6.dontHaveAccount-heading {
    height: 9.6rem;
  }
  h6.already-have-headings {
    font-size: 16px;
  }
  h6.forgotPassword {
    font-size: 18px;
  }
  label {
    font-size: 18px;
  }
}
@media (min-width: 1023px) and (max-width: 1025px){
  .signUp-section{
    margin-bottom: 18%;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .signUp-section {
    width: 50%;
  }
  .forgotten-password-section{
    margin-bottom: 41% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
}
@media (min-width: 900px) and (max-width: 992px){
  .phone-number-feild {
     margin-bottom: 0; 
}
.forgotten-password-section {
  min-height: calc(100vh - 467px);
}
}
