* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-Footer {
  margin-top: 5%;
}

h2.FooterRating-heading {
  color: #f47321;
  font-family: "Gugi", cursive;
  font-size: 28px;
}

h2.footerReviews-heading {
  color: #f47321;
  font-family: "Gugi", cursive;
  font-size: 28px;
  margin-bottom: 25px;
}
span.footer-stars-icon {
  position: relative;
  top: 11px;
}

hr {
  color: #afafaf;
}

// span.footer-stars-icon {
//     position: relative;
//     top: -6px;
// }
span.footer-stars-icon i {
  font-size: 43px;
}

.footerReviews-section {
  float: right;
  position: relative;
  right: 55px;
}

h6.fifth-rating,
h6.fourth-rating {
  margin-right: 15px;
  font-size: 15px;
  font-weight: 700;
}

h6.third-rating,
h6.second-rating,
h6.first-rating {
  margin-right: 15px;
  font-size: 15px;
  font-weight: 700;
}

.rating-number {
  display: flex;
}

h1#test {
  font-size: 64px;
  font-weight: 200;
  display: inline-block;
  margin-right: 20px;
}

img.footer-stars-icon {
  width: 6%;
  margin-top: -6%;
}

.progress-line {
  width: 100%;
}
img.entyLine {
  width: 115%;
  object-fit: cover;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .main-Footer {
    display: block;
    margin-top: 15%;
  }
  .footerReviews-section {
    right: 0;
  }

  h2.FooterRating-heading {
    font-size: 18px;
  }

  h2.footerReviews-heading {
    font-size: 12px;
    margin-bottom: 31px;
  }
  .footerReviews-part {
    text-align: center;
  }
  span.footer-stars-icon i {
    font-size: 16px;
    padding: 4px;
  }
  h1#test {
    font-size: 64px;
    display: block;
    margin: 0;
    margin-bottom: 14px;
  }

  h6.fourth-rating {
    font-size: 12px;
  }

  h6.fifth-rating {
    font-size: 12px;
  }

  h6.third-rating {
    font-size: 12px;
  }

  h6.second-rating {
    font-size: 12px;
  }

  h6.first-rating {
    font-size: 12px;
  }

  img.footer-stars-icon {
    width: auto;
    margin-top: -3%;
  }

  .FooterRating-section {
    width: 100%;
  }
  .test {
    width: 50%;
  }
  span.footer-stars-icon {
    position: relative;
    top: -5px;
  }
  img.entyLine {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .main-Footer {
    display: block;
  }

  h2.footerReviews-heading {
    font-size: 15px;
  }
  .footerReviews-part {
    text-align: center;
  }
  h1#test {
    font-size: 64px;
    display: block;
    margin: 0;
  }
  span.footer-stars-icon i {
    font-size: 20px;
  }
  .progressBar-section h6 {
    font-size: 15px;
  }
  .footerReviews-section {
    right: 0;
  }
  img.entyLine {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  h1#test {
    font-size: 64px;
  }
  span.footer-stars-icon i {
    font-size: 40px;
  }
  h2.footerReviews-heading {
    font-size: 19px;
  }
  h2.FooterRating-heading {
    font-size: 19px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
}
