$parrent-Color: #f47321;

.timer-slide {
  margin-top: 10px;
  font-family: "lato";

  .time-name {
    color: $parrent-Color;
    font-family: "gugi";
    font-size: 16px;
    margin-top: 35px;
  }

  .timer-btn {
    padding: 5px 20px;
    margin: 18px 10px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: #fff;
    font-size: 12px;
    color: BLACK;
  }
  .timer-btn:hover {
    background-color: $parrent-Color;
    color: #fff;
    border: 1px solid transparent;
  }
  .active {
    background-color: $parrent-Color;
    color: #fff;
    border: 1px solid transparent;
  }

  .x-marksvg {
    width: 14px;
    height: 14px;
    object-fit: cover;
    cursor: pointer;
    opacity: 0.5;
  }
  .add-another-services img.x-marksvg {
    margin-right: 10px;
    opacity: 1;
  }

  .selected-services {
    margin: 15px 0px 15px 0px;
    .serviceSelectedBTN {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      .SelectedBTN {
        border: 1px solid black;
        display: flex;
        align-items: center;
        width: 95%;
        margin: 10px 0px;
        margin-right: 16px;
        justify-content: space-between;
        cursor: unset;
      }
    }

    .serivice-name {
      color: $parrent-Color;
      margin-top: 40px;
      margin-bottom: 14px;
      font-family: "gugi";
      font-size: 16px;
    }
  }

  .add-another-services {
    color: $parrent-Color;
    margin: 25px 0px 5px 0px;

    img {
      color: $parrent-Color;
    }
  }
  .add-another-comment {
    margin-left: 10px;
    color: $parrent-Color;
    .small-comment {
      color: black;
    }
  }

  .cost-section {
    display: flex;
    color: $parrent-Color;
    justify-content: space-between;
    margin: 25px 25px 0 0;
    .first-container {
      text-align: start;
      font-size: 15px;
    }
    .second-container {
      text-align: end;

      .est-time {
        color: #292929;
        font-size: 13px;
      }
    }
  }
  .cnf-booking {
    text-align: center;
    .cnf-btn {
      background-color: $parrent-Color;
      margin: 15px 0;
      border: 0;
      padding: 11px 115px;
      box-shadow: none;
    }
    .smalText {
      padding: 0 30px;

      .cnf-booking-small {
        font-size: 10px;
        color: #858585;
      }
    }
  }
  .CommentIcon {
    color: #f47321;
    padding-right: 7px;
  }
}
span.commentHeading {
  color: #000000;
  font-size: 11px;
}

.addComment {
  width: 100%;
  padding: 8px;
  border: 1px solid #cbcbcb;
  outline: none !important;
}
.addComment-service {
  margin-bottom: 4px;
  margin-top: 0;
}

.addComment:hover {
  border-color: #f47321;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (max-width: 768px) {
  .timer-slide .cnf-booking .cnf-btn {
    padding: 10px 70px;
  }
  .timer-slide .cost-section .first-container {
    color: BLACK;
  }
  .timer-slide .selected-services .serviceSelectedBTN .SelectedBTN {
    font-size: 14px;
  }
  span.commentHeading {
    font-size: 11px;
    color: #858585;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  span.commentHeading {
    font-size: 12px;
  }
  .addComment-service {
    margin-top: -5px;
  }
  .timer-slide .cnf-booking .smalText .cnf-booking-small {
    padding: 0 30px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .timer-slide .cnf-booking .smalText .cnf-booking-small {
    padding: 0 43px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px) {
  .timer-slide .cnf-booking .smalText .cnf-booking-small {
    padding: 0 43px;
  }
}
