.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border: 0px;
  font-family: "lato";
  line-height: 1.125em;
button.react-calendar__tile {
    color: black;
}
}
.react-calendar__navigation button.react-calendar__navigation__arrow {
  color: black;
  font-size: 25px;
}
.react-calendar__navigation button {
  color: #f47321;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 600;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
  font-weight: 100;
  font-size: 11px;
}
abbr {
  font-size: 14px;
}
.react-calendar__month-view__days__day {
  margin: 6px !important;
  padding: 14px;
  flex: 0 0 11.2857% !important;
}
.react-calendar__tile:disabled {
  border-radius: 50%;
  color: #b9b9b9 !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #f47321;
  border-radius: 50%;
  padding: 14px;
}
.react-calendar__tile--now {
  background: #f4732133;
  border-radius: 50%;
  font-weight: bold;
  color: #f47321;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #f4732133;
  border-radius: 50%;
  font-weight: bold;
  color: #f47321;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #f47321;
  border-radius: 50%;
  font-weight: bold;
  color: white;
}
.react-calendar__month-view__days__day--weekend {
  color: #000000;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #f47321;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #f47321;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #f47321;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 50%;
  background: #f47321;
  color: white !important;
}

.offcanvas-body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.offcanvas-body::-webkit-scrollbar-track {
  background: rgb(194, 194, 194); /* color of the tracking area */
  border-radius: 10px;
}

.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: #f47321; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(255, 255, 255); /* creates padding around scroll thumb */
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
@media (max-width: 767px) {
  .react-calendar {
    margin: auto;
  }
  .offcanvas.offcanvas-end.show {
    width: 100% !important;
    padding: 10px !important;
  }
  .offcanvas-body {
    padding: 0;
  }
  abbr {
    font-size: 13px;
  }
  .react-calendar__navigation {
    padding-right: 45px;
  }
  .react-calendar__month-view__weekdays__weekday {
    flex: 0 0 12.58% !important;
  }
  .react-calendar__month-view__days__day {
    margin: 12px 3px !important;
    padding: 12px;
    flex: 0 0 11.2857% !important;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    padding: 12px;
  }
  .timer-slide .timer-btn {
    margin: 15px;
  }
}
