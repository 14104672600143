* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

div#Footer-comment {
  margin-top: 3%;
}
.serviceSection {
  margin-top: 5%;
}

div#Footer-comment .container {
  max-height: 700px;
  overflow-y: auto;
}

h4.jhon-heading {
  margin: 3px 0;
  font-size: 18px;
  color: #292929;
  line-height: 10px;
}

.footerComment-section {
  display: flex;
  margin: 25px 0px;
  align-items: center;
  position: relative;
}

.img-section {
  display: flex;
  align-self: center;
}

.comment {
  width: 70%;
  padding: 12px 40px 0px;
}

span.jhon-date {
  color: #858585;
  font-size: 14px;
  top: -10px;
  position: relative;
}

p.jhon-para {
  color: #858585;
  font-size: 16px;
  padding: 0px;
  margin-bottom: 0;
  // margin-top: 10px;
  word-break: break-word;
}

span.jhonRick-heading {
  color: #000000;
  font-weight: 400;
}

img.smallbarbar-icon {
  margin: 0px 12px;
}

h6.leoName-heading {
  color: #f47321;
  font-size: 13px;
}

em {
  font-style: normal;
  color: #8d8d89;
}

p.jhon-para-2 {
  color: #858585;
  font-size: 14px;
  padding: 6px 0px;
  margin-bottom: 0;
}

img.jhon_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
div#service-section {
  padding: 0;
}
.rating-Section label.dv-star-rating-star {
  font-size: 28px;
}
.rating-Section label.dv-star-rating-star {
  font-size: 28px;
}
.footerComment-section label.dv-star-rating-star {
  font-size: 23px;
}
.footerComment-section label.dv-star-rating-star {
  font-size: 16px;
}
// scrollbar CSS
div#Footer-comment .container::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

div#Footer-comment .container::-webkit-scrollbar-track {
  background: rgb(194, 194, 194); /* color of the tracking area */
  border-radius: 10px;
}

div#Footer-comment .container::-webkit-scrollbar-thumb {
  background-color: #f47321; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(255, 255, 255); /* creates padding around scroll thumb */
}
p.jhon-para-small {
  color: #858585;
  font-size: 13px;
  padding: 0px;
  margin-bottom: 0;
  word-break: break-word;
}
.Customer-Image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  background: #ffbd8e;
  font-size: 35px;
  color: #f47321;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* media queries */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .comment {
    width: -webkit-fill-available;
    padding: 10px 0px;
  }
  div#Footer-comment {
    margin-bottom: 15%;
  }

  img.jhon_img {
    width: 61px;
    height: 61px;
    margin: 0px 10px;
    object-fit: cover;
  }

  img.footerCommentStar-icons {
    width: 15%;
  }

  h4.jhon-heading {
    font-size: 15px;
  }

  span.jhon-date {
    font-size: 12px;
  }

  p.jhon-para-2 {
    font-size: 12px;
  }

  span.jhonRick-heading {
    font-size: 10px;
  }

  h6.leoName-heading {
    font-size: 13px;
    padding-top: 10px;
  }
  div#service-section {
    padding: 0 15px;
  }
  .footerComment-section {
    margin: 10px 0;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .rating-Section label.dv-star-rating-star {
    font-size: 22px;
  }
  .dv-star-rating {
    width: max-content;
  }
  .footerComment-section label.dv-star-rating-star {
    font-size: 13px;
    padding: 0 1px;
  }
  p.jhon-para {
    font-size: 12px;
    // padding-bottom: 10px;
  }
  div#Footer-comment {
    margin-bottom: 10%;
  }
  div#footerComment-heading-part {
    padding-left: 10%;
    background-color: #fff6ea;
    border-radius: 8px;
  }
  .Customer-Image {
    width: 61px;
    height: 61px;
    margin: 0px 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .comment {
    width: 45%;
    padding: 35px 20px;
  }
  div#Footer-comment {
    margin-bottom: 5%;
  }

  p.jhon-para {
    font-size: 12px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .searchBarbar-Section {
    height: calc(100vh - 412px);
    overflow-y: scroll;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .comment {
    width: 60%;
    padding: 12px 20px;
  }
  div#service-section {
    padding: 0 10px;
  }
  p.jhon-para {
    font-size: 14px;
  }
  .rating-Section label.dv-star-rating-star {
    font-size: 16px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px) {
}
