i.fa.fa-phone.call-icon {
  width: 17px;
  height: 17px;
  margin-bottom: 8px;
  margin-top: 14px;
}
.social-media-details a {
  text-decoration: none;
}
a .instagram-details {
  color: black;
}
