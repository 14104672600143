.mobile-view {
  display: none;
}

.desktop-view {
  display: block;
}
.carousel-root {
  width: 100%;
}
.AddScreenSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #fff;
}
.thankyou-section {
  margin: 10px 0px;
}
.AddDetails {
  display: flex;
  align-items: center;
}

button.DownloadBtn {
  color: #fff;
  background-color: #fd6a02;
  border: 0;
  padding: 10px 15px;
  border-radius: 9px;
  font-size: 13px;
}

h5.addHeading {
  font-size: 15px;
}

h6.AddSmallheading {
  font-size: 12px;
  color: #858585;
}
img.AddLogoIcon {
  width: 80px;
  margin-left: 5px;
}

.cutSec {
  // padding-right: 14px;
  clip-path: polygon(
    8% 0,
    0 8%,
    42% 50%,
    0 92%,
    8% 100%,
    50% 58%,
    92% 100%,
    100% 92%,
    58% 50%,
    100% 8%,
    93% 0,
    50% 42%
  );
  padding: 7px;
  background-color: #858585;
  width: 5%;
  height: 15px;
}

.AddHeadingSec {
  padding: 0 4px;
}

h5.addHeading {
  font-weight: 700;
  font-size: 15px;
}
@media (max-width: 767px) {
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .AddScreenSec {
    display: none;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .AddScreenSec {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1249px) {
  .AddScreenSec {
    display: none;
  }
}
