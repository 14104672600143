.spinner-container {
  height: -webkit-fill-available;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 1;
  position: fixed;
  z-index: 20;
}
.spinner {
  margin: auto;
  border: 3px solid #7f7f7f;
  width: 90px;
  height: 90px;
  /* display: inline-block; */
  /* position: absolute; */
  /* top: 39%; */
  border-radius: 50%;
  border-right: 3px solid #fd6a02;
  /* text-align: center; */
  animation-name: spin;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  /*50% {width:24px;height:24px} /*shrinking effect*/
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  /*50% {width:24px;height:24px} /*shrinking effect*/
  100% {
    -webkit-transform: rotate(360deg);
  }
}
