.wrapper {
  max-width: 100%;
  margin: 0 auto;
  background-color: white;
  padding: 75px 50px;
  padding-bottom: 0px;
}
button.rec.rec-arrow.rec.rec-arrow-left {
  box-shadow: none;
  background-color: #fff;
}
button.rec.rec-arrow.rec.rec-arrow-right {
  background-color: #fff !important;
  border: 0px solid white !important;
  outline-color: #fff !important;
}
.rec-arrow {
  color: #f47321 !important;
}

.slick-slide a {
  color: white;
  font-size: 16px;
  font-family: "PT Sans", sans-serif;
}
p.test-para {
  font-size: 18px;
  margin-top: 6%;
}

.slick-slide p {
  line-height: 24px;
}

.slick-slide p:last-of-type {
  margin-bottom: 0;
}

.slick-slide p:first-of-type {
  font-weight: bold;
}

.slick-next:before {
  position: relative;
  top: -15px;
  content: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1552126/right-carousel-arrow.png");
}

.slick-prev:before {
  position: relative;
  top: -15px;
  right: 20px;
  content: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1552126/left-carousel-arrow.png");
}

.columns.small-12 {
  text-align: center;
}

.slick-slide img {
  display: initial !important;
}

.img-colleague {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

h3.christoper-name {
  text-align: center;
  color: black;
  font-size: 23px;
  margin-bottom: 0;
  margin-top: 30px;
}

h5.christoper-name {
  color: black;
  font-size: 18px;
  margin-top: 13px;
}

h6.review {
  color: black;
  font-size: 14px;
  text-align: center;
}
.rec.rec-pagination {
  display: none !important;
}

.slick-prev:before {
  content: "<" !important;
  color: #f47321 !important;
  opacity: 1 !important;
  font-family: monospace !important;
  font-size: 45px !important;
  font-weight: 700 !important;
  line-height: 0 !important;
}

.slick-next:before {
  content: ">" !important;
  color: #f47321 !important;
  opacity: 1 !important;
  font-family: monospace !important;
  font-size: 45px !important;
  font-weight: 700 !important;
  line-height: 0 !important;
}

h2.colleagues {
  font-family: "Gugi", cursive;
  color: #f47321;
  font-size: 28px;
  margin-top: 5%;
}

.read-more {
  color: #f47321 !important;
  cursor: pointer;
  text-decoration: underline;
}

.custom-profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ffbd8e;
  font-size: 35px;
  color: #f47321;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.rec.rec-arrow.rec.rec-arrow-right {
  background-color: #fff;
  box-shadow: none;
}
h3.colleguesModalHeading {
  font-size: 28px;
  font-family: gugi;
  color: #f47321;
  margin-bottom: 5px;
}
.slider-star {
  text-align: center;
}
p.colleaguesPara {
  margin: 70px 0px;
  font-size: 18px;
  margin-bottom: 0px;
  font-family: "Lato";
}
.colleaguPopup {
  width: 100%;
  padding: 15px 35px;
}
.colleguesActive {
  position: relative;
  width: fit-content;
  margin: auto;
  border-radius: 50% !important;
  top: 5px;
  border: 4px solid transparent;
}
// .colleguesActive:hover {
//   border: 4px solid #f47321;
// }
.img-colleague-active:hover {
  border: 4px transparent;
}
.colleguesActive:hover {
  border: 4px solid #f47321;
}
h6.choose-heading {
  font-size: 14px;
  color: #292929;
  font-family: lato;
}
.img-colleague-active {
  border: 4px solid #f47321;
  width: 104px;
  height: 104px;
  border-radius: 50%;
  transition: 0.1s all;
}
img.selected-icon {
  position: absolute;
  right: -5px;
  top: 5px;
}
h6.modal-chooseBarber {
  color: #292929;
  margin-bottom: 40px;
  font-family: lato;
}
.modal-backdrop{

  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.5);
  --bs-backdrop-opacity: 1;

}
// this css for safari browser 
.removeSpace {
  outline: 5px solid rgb(255 255 255);
  position: relative;
  z-index: 0;
}
// end safari browser css
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  h2.colleagues {
    font-size: 18px;
  }
  p.colleaguesPara {
    margin: 40px 0;
    font-size: 14px;
  }
  .wrapper {
    padding-bottom: 10px;
    padding: 40px 10px;
  }
  h3.christoper-name {
    font-size: 14px;
  }
  h6.review {
    font-size: 10px;
    margin-top: -5px;
  }
  .colleaguPopup {
    width: 100%;
    padding: 0;
  }
  h6.review {
    font-size: 12px;
  }
  h3.christoper-name {
    font-size: 15px;
    padding: 0 12px;
  }
  .colleguesActive {
    border-radius: 50% !important;
  }
  p.colleaguesPara {
    margin: 43px 0;
  }
  button.rec-arrow-left {
    display: none;
  }
  button.rec-arrow-right {
    display: none;
  }
  button.rec-arrow-right {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  h6.review {
    font-size: 12px;
  }
  p.colleaguesPara {
    margin: 43px 0;
  }
  h3.christoper-name {
    font-size: 15px;
  }
  .colleguesActive {
    border-radius: 50% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  h2.colleagues {
    font-size: 24px;
  }
  h3.christoper-name {
    font-size: 15px;
  }
  h6.review {
    font-size: 12px;
  }
  .colleguesActive {
    border-radius: 50% !important;
  }
  .colleaguPopup {
    padding: 15px 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1249px) {
  .colleguesActive {
    border-radius: 50% !important;
  }
}

@media (max-width: 768px) {
  .img-colleague {
    width: 61px;
    height: 61px;
  }
  .img-colleague-active {
    width: 65px !important;
    height: 65px !important;
  }
  .custom-profileImage {
    width: 61px;
    height: 61px;
  }
  img.selected-icon {
    width: 25%;
    right: 0;
  }
}
